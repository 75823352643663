function piiPresent() {
  const disallowed = ['firstname', 'lastname', 'nickname', 'address', 'gender', 'profileurl', 'email', 'pwd', 'fname', 'lname', 'user'];
  const params = document.location.search.slice(1).split("&").map((keyVal) => {
    return keyVal.split("=")[0];
  });

  const presentAndDisallowed = params.filter(param => disallowed.includes(param));

  return document.location.href.includes("@") || presentAndDisallowed.length > 0;
}

function disableGA() {
  window['ga-disable-UA-4008079-1'] = true;
}

export { piiPresent, disableGA };
